import Vue from "vue";
import Vuex from "vuex";
import { Howl } from "howler";
import * as types from "./mutation-types";

Vue.use(Vuex);
const gameEnd = new Howl({
  src: ["../../static/finish.mp3", "../../static/finish.ogg"],
  volume: 0.5,
});

const correct = new Howl({
  src: ["../../static/correct.mp3", "../../static/correct.ogg"],
  volume: 0.5,
});

const wrong = new Howl({
  src: ["../../static/gameover.mp3", "../../static/gameover.ogg"],
  rate: 1.3,
  volume: 0.5,
});

const store = new Vuex.Store({
  state: {
    gameStarted: false,
    difficulty: 'easy',
    remainingGuesses: 6,
    correctCount: 0,
    sound: true,
    showHints: false,
    showWrongAnswers: false,
  },
  getters: {
    gameStarted: (state) => state.gameStarted,
    difficulty: (state) => state.difficulty,
    sound: (state) => state.sound,
    showHints: (state) => state.showHints,
    showWrongAnswers: (state) => state.showWrongAnswers,
    remainingGuesses: (state) => state.remainingGuesses,
    correctCount: (state) => state.correctCount,
  },
  mutations: {
    setShowHints(state, value) {
      state.showHints = value;
    },
    setShowWrongAnswers(state, value) {
      state.showWrongAnswers = value;
    },
    setDifficulty(state, value) {
      state.difficulty = value;
    },
    [types.TOGGLE_SOUND](state) {
      state.sound = !state.sound;
      localStorage.setItem("sound", state.sound);
    },
    gameStarted(state) {
      state.gameStarted = true;
    },
    remainingGuesses(state) {
      state.remainingGuesses--;
    },
    correctCount(state) {
      state.correctCount++;
    },
  },
  actions: {
    playSound({ state }, type) {
      if (state.sound) {
        switch (type) {
          case "game-end":
            gameEnd.play();
            break;
          case "correct":
            correct.play();
            break;
          case "wrong":
            wrong.play();
            break;
        }
      }
    },
    toggleSound({ commit }) {
      commit(types.TOGGLE_SOUND);
    },
  },
  modules: {},
});
export default store;
