import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { countriesStore } from "./store/countries";
import "./style.css";
import Toasted from "vue-toasted";
import VueSweetalert2 from "vue-sweetalert2";
import VueConfetti from "vue-confetti";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);
Vue.use(VueConfetti);
Vue.config.productionTip = false;
Vue.use(Toasted, {
  duration: 1500, // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
});

new Vue({
  router,
  store,
  vuetify,
  countriesStore,
  render: (h) => h(App),
}).$mount("#app");
