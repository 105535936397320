<template>
  <div id="navigation-mobile">
    <ul
      v-for="item in tabs"
      :key="item.path"
      :to="item.path"
      active-class
      @click="$router.push(item.path)"
    >
      <li>{{ item.name }}</li>
    </ul>
    <dark-mode></dark-mode>
  </div>
</template>

<script>
import DarkMode from "./DarkMode.vue";
export default {
  name: "NavBarMobile",
  components: { DarkMode },
  data() {
    return {
      tabs: [
        { path: "/", name: "Home" },
        { path: "/game", name: "Game" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#navigation-mobile {
  padding: 100px 0 0 20px;
  overflow: hidden;

  ul {
    list-style: none;
    width: 200px;
    padding-left: 20px;
    padding-top: 2px;

    li {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        color: #111;
      }
    }
  }
}
</style>
