<template>
  <div>
    <v-toolbar flat fixed>
      <v-btn icon
        ><img src="@/assets/earth.png" style="height: 32px; width: 32px" alt=""
      /></v-btn>
      <v-toolbar-title
        class=""
        style="cursor: pointer"
        @click="$router.push('/')"
        title="Home"
        ><span style="color: #bae0bd">Country</span
        ><span style="color: #c2e8ff">Guess</span></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items fixed-tabs class="hidden-sm-and-down">
        <v-tabs height="70">
          <v-tab
            v-for="item in tabs"
            :key="item.path"
            :to="item.path"
            active-class
            >{{ item.name }}</v-tab
          >
        </v-tabs>
        <dark-mode></dark-mode>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>
<script>
import DarkMode from "./DarkMode.vue";
export default {
  name: "Header",
  components: {
    DarkMode,
  },
  data() {
    return {
      tabs: [
        { path: "/", name: "Home" },
        { path: "/game", name: "Game" },
        { path: "/contact", name: "Contact" },
      ],
    };
  },
};
</script>
