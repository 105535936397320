export const countriesStore = [
  { name: "Afghanistan", code: "AF", difficulty: "medium" },
  { name: "Albania", code: "AL", difficulty: "hard" },
  { name: "Algeria", code: "DZ", difficulty: "hard" },
  { name: "Andorra", code: "AD", difficulty: "hard" },
  { name: "Angola", code: "AO", difficulty: "hard" },
  { name: "Anguilla", code: "AI", difficulty: "hard" },
  { name: "Antigua and Barbuda", code: "AG", difficulty: "hard" },
  { name: "Argentina", code: "AR", difficulty: "easy" },
  { name: "Armenia", code: "AM", difficulty: "medium" },
  { name: "Aruba", code: "AW", difficulty: "hard" },
  { name: "Australia", code: "AU", difficulty: "easy" },
  { name: "Austria", code: "AT", difficulty: "easy" },
  { name: "Azerbaijan", code: "AZ", difficulty: "easy" },
  { name: "Bahamas", code: "BS", difficulty: "hard" },
  { name: "Bahrain", code: "BH", difficulty: "medium" },
  { name: "Bangladesh", code: "BD", difficulty: "medium" },
  { name: "Barbados", code: "BB", difficulty: "hard" },
  { name: "Belarus", code: "BY", difficulty: "hard" },
  { name: "Belgium", code: "BE", difficulty: "easy" },
  { name: "Belize", code: "BZ", difficulty: "hard" },
  { name: "Benin", code: "BJ", difficulty: "hard" },
  { name: "Bermuda", code: "BM", difficulty: "hard" },
  { name: "Bhutan", code: "BT", difficulty: "hard" },
  { name: "Bolivia", code: "BO", difficulty: "hard" },
  { name: "Botswana", code: "BW", difficulty: "hard" },
  { name: "Brazil", code: "BR", difficulty: "easy" },
  { name: "Brunei", code: "BN", difficulty: "hard" },
  { name: "Bulgaria", code: "BG", difficulty: "medium" },
  { name: "Burkina Faso", code: "BF", difficulty: "hard" },
  { name: "Burundi", code: "BI", difficulty: "hard" },
  { name: "Cambodia", code: "KH", difficulty: "hard" },
  { name: "Cameroon", code: "CM", difficulty: "medium" },
  { name: "Canada", code: "CA", difficulty: "easy" },
  { name: "Cape Verde", code: "CV", difficulty: "hard" },
  { name: "Chad", code: "TD", difficulty: "medium" },
  { name: "Chile", code: "CL", difficulty: "medium" },
  { name: "China", code: "CN", difficulty: "easy" },
  { name: "Colombia", code: "CO", difficulty: "easy" },
  { name: "Comoros", code: "KM", difficulty: "hard" },
  { name: "Democratic Republic of the Congo", code: "CD", difficulty: "mediun" },
  { name: "Costa Rica", code: "CR", difficulty: "easy" },
  { name: "Croatia", code: "HR", difficulty: "easy" },
  { name: "Cuba", code: "CU", difficulty: "medium" },
  { name: "Cyprus", code: "CY", difficulty: "easy" },
  { name: "Czech Republic", code: "CZ", difficulty: "easy" },
  { name: "Denmark", code: "DK", difficulty: "easy" },
  { name: "Djibouti", code: "DJ", difficulty: "hard" },
  { name: "Dominica", code: "DM", difficulty: "hard" },
  { name: "Dominican Republic", code: "DO", difficulty: "medium" },
  { name: "Ecuador", code: "EC", difficulty: "medium" },
  { name: "Egypt", code: "EG", difficulty: "easy" },
  { name: "El Salvador", code: "SV", difficulty: "medium" },
  { name: "Equatorial Guinea", code: "GQ", difficulty: "medium" },
  { name: "Eritrea", code: "ER", difficulty: "hard" },
  { name: "Estonia", code: "EE", difficulty: "hard" },
  { name: "Ethiopia", code: "ET", difficulty: "hard" },
  { name: "Finland", code: "FI", difficulty: "easy" },
  { name: "France", code: "FR", difficulty: "easy" },
  { name: "Gabon", code: "GA", difficulty: "hard" },
  { name: "Gambia", code: "GM", difficulty: "medium" },
  { name: "Georgia", code: "GE", difficulty: "medium" },
  { name: "Germany", code: "DE", difficulty: "easy" },
  { name: "Ghana", code: "GH", difficulty: "medium" },
  { name: "Greece", code: "GR", difficulty: "easy" },
  { name: "Greenland", code: "GL", difficulty: "hard" },
  { name: "Grenada", code: "GD", difficulty: "hard" },
  { name: "Guatemala", code: "GT", difficulty: "hard" },
  { name: "Guinea", code: "GN", difficulty: "hard" },
  { name: "Guyana", code: "GY", difficulty: "hard" },
  { name: "Haiti", code: "HT", difficulty: "hard" },
  { name: "Honduras", code: "HN", difficulty: "hard" },
  { name: "Hong Kong", code: "HK", difficulty: "medium" },
  { name: "Hungary", code: "HU", difficulty: "easy" },
  { name: "Iceland", code: "IS", difficulty: "medium" },
  { name: "India", code: "IN", difficulty: "easy" },
  { name: "Indonesia", code: "ID", difficulty: "easy" },
  { name: "Iran", code: "IR", difficulty: "easy" },
  { name: "Iraq", code: "IQ", difficulty: "easy" },
  { name: "Ireland", code: "IE", difficulty: "medium" },
  { name: "Israel", code: "IL", difficulty: "easy" },
  { name: "Italy", code: "IT", difficulty: "easy" },
  { name: "Jamaica", code: "JM", difficulty: "hard" },
  { name: "Japan", code: "JP", difficulty: "easy" },
  { name: "Jordan", code: "JO", difficulty: "easy" },
  { name: "Kazakhstan", code: "KZ", difficulty: "medium" },
  { name: "Kenya", code: "KE", difficulty: "easy" },
  { name: "North Korea", code: "KP", difficulty: "easy" },
  { name: "South Korea", code: "KR", difficulty: "easy" },
  { name: "Kuwait", code: "KW", difficulty: "hard" },
  { name: "Kyrgyzstan", code: "KG", difficulty: "medium" },
  { name: "Laos", code: "LA", difficulty: "hard" },
  { name: "Latvia", code: "LV", difficulty: "hard" },
  { name: "Lebanon", code: "LB", difficulty: "medium" },
  { name: "Lesotho", code: "LS", difficulty: "hard" },
  { name: "Liberia", code: "LR", difficulty: "easy" },
  { name: "Libya", code: "LY", difficulty: "easy" },
  { name: "Lithuania", code: "LT", difficulty: "easy" },
  { name: "Luxembourg", code: "LU", difficulty: "easy" },
  { name: "Macedonia", code: "MK", difficulty: "easy" },
  { name: "Madagascar", code: "MG", difficulty: "medium" },
  { name: "Malawi", code: "MW", difficulty: "hard" },
  { name: "Malaysia", code: "MY", difficulty: "medium" },
  { name: "Mali", code: "ML", difficulty: "medium" },
  { name: "Malta", code: "MT", difficulty: "hard" },
  { name: "Mauritania", code: "MR", difficulty: "medium" },
  { name: "Mexico", code: "MX", difficulty: "easy" },
  { name: "Moldova", code: "MD", difficulty: "medium" },
  { name: "Monaco", code: "MC", difficulty: "easy" },
  { name: "Mongolia", code: "MN", difficulty: "hard" },
  { name: "Montenegro", code: "ME", difficulty: "hard" },
  { name: "Montserrat", code: "MS", difficulty: "hard" },
  { name: "Morocco", code: "MA", difficulty: "easy" },
  { name: "Mozambique", code: "MZ", difficulty: "hard" },
  { name: "Myanmar", code: "MM", difficulty: "hard" },
  { name: "Namibia", code: "NA", difficulty: "hard" },
  { name: "Nauru", code: "NR", difficulty: "hard" },
  { name: "Nepal", code: "NP", difficulty: "medium" },
  { name: "Netherlands", code: "NL", difficulty: "easy" },
  { name: "New Caledonia", code: "NC", difficulty: "hard" },
  { name: "New Zealand", code: "NZ", difficulty: "medium" },
  { name: "Nicaragua", code: "NI", difficulty: "hard" },
  { name: "Niger", code: "NE", difficulty: "hard" },
  { name: "Nigeria", code: "NG", difficulty: "medium" },
  { name: "Norway", code: "NO", difficulty: "easy" },
  { name: "Oman", code: "OM", difficulty: "hard" },
  { name: "Pakistan", code: "PK", difficulty: "medium" },
  { name: "Palestine", code: "PS", difficulty: "hard" },
  { name: "Panama", code: "PA", difficulty: "medium" },
  { name: "Papua New Guinea", code: "PG", difficulty: "medium" },
  { name: "Paraguay", code: "PY", difficulty: "medium" },
  { name: "Peru", code: "PE", difficulty: "medium" },
  { name: "Philippines", code: "PH", difficulty: "medium" },
  { name: "Poland", code: "PL", difficulty: "easy" },
  { name: "Portugal", code: "PT", difficulty: "easy" },
  { name: "Puerto Rico", code: "PR", difficulty: "hard" },
  { name: "Qatar", code: "QA", difficulty: "easy" },
  { name: "Republic of Congo", code: "CG", difficulty: "hard" },
  { name: "Romania", code: "RO", difficulty: "easy" },
  { name: "Russian Federation", code: "RU", difficulty: "easy" },
  { name: "Rwanda", code: "RW", difficulty: "hard" },
  { name: "Saudi Arabia", code: "SA", difficulty: "easy" },
  { name: "Senegal", code: "SN", difficulty: "medium" },
  { name: "Serbia", code: "RS", difficulty: "easy" },
  { name: "Sierra Leone", code: "SL", difficulty: "hard" },
  { name: "Singapore", code: "SG", difficulty: "medium" },
  { name: "Slovakia", code: "SK", difficulty: "easy" },
  { name: "Slovenia", code: "SI", difficulty: "medium" },
  { name: "Solomon Islands", code: "SB", difficulty: "hard" },
  { name: "Somalia", code: "SO", difficulty: "hard" },
  { name: "South Africa", code: "ZA", difficulty: "easy" },
  { name: "Spain", code: "ES", difficulty: "easy" },
  { name: "Sri Lanka", code: "LK", difficulty: "hard" },
  { name: "Sudan", code: "SD", difficulty: "hard" },
  { name: "Suriname", code: "SR", difficulty: "hard" },
  { name: "Swaziland", code: "SZ", difficulty: "hard" },
  { name: "Sweden", code: "SE", difficulty: "easy" },
  { name: "Switzerland", code: "CH", difficulty: "easy" },
  { name: "Syrian Arab Republic", code: "SY", difficulty: "medium" },
  { name: "Taiwan", code: "TW", difficulty: "hard" },
  { name: "Tajikistan", code: "TJ", difficulty: "hard" },
  { name: "Tanzania", code: "TZ", difficulty: "hard" },
  { name: "Thailand", code: "TH", difficulty: "hard" },
  { name: "Timor-Leste", code: "TL", difficulty: "hard" },
  { name: "Togo", code: "TG", difficulty: "hard" },
  { name: "Trinidad and Tobago", code: "TT", difficulty: "hard" },
  { name: "Tunisia", code: "TN", difficulty: "hard" },
  { name: "Turkey", code: "TR", difficulty: "easy" },
  { name: "Turkmenistan", code: "TM", difficulty: "medium" },
  { name: "Turks and Caicos Islands", code: "TC", difficulty: "hard" },
  { name: "Uganda", code: "UG", difficulty: "medium" },
  { name: "Ukraine", code: "UA", difficulty: "easy" },
  { name: "United Arab Emirates", code: "AE", difficulty: "easy" },
  { name: "United Kingdom", code: "GB", difficulty: "easy" },
  { name: "United States", code: "US", difficulty: "easy" },
  { name: "Uruguay", code: "UY", difficulty: "easy" },
  { name: "Uzbekistan", code: "UZ", difficulty: "medium" },
  { name: "Vanuatu", code: "VU", difficulty: "hard" },
  { name: "Venezuela", code: "VE", difficulty: "hard" },
  { name: "Vietnam", code: "VN", difficulty: "easy" },
  { name: "United States Virgin Islands", code: "VI", difficulty: "hard" },
  { name: "Western Sahara", code: "EH", difficulty: "hard" },
  { name: "Yemen", code: "YE", difficulty: "hard" },
  { name: "Zambia", code: "ZM", difficulty: "hard" },
  { name: "Zimbabwe", code: "ZW", difficulty: "hard" },
];
