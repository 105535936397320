<template>
  <v-app>
    <NavBarMobile v-if="mobileView" />
    <Header v-if="!mobileView"></Header>
    <div class="content" :class="{ open: showNav }">
      <div style="height: 20px"></div>
      <div id="navigation-icon" v-if="mobileView" @click="showNav = !showNav">
        <div class="model" v-if="showNav" v-scroll-lock="true"></div>
        <v-icon color="primary">menu</v-icon>
      </div>
      <router-view />
    </div>
    <Footer></Footer>

    <!-- <SoundToggle></SoundToggle> -->
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import NavBarMobile from "./components/NavBarMobile";
//import SoundToggle from "./components/SoundToggle";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    NavBarMobile,
    Footer,
  },
  data() {
    return {
      mobileView: false,
      showNav: false,
      showFooter: false,
      page: 1,
    };
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 1024;
    },
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },

  watch: {
    $route(to, from) {
      if (to > from) this.showNav = false;
    },
  },
};
</script>
<style>
#app {
  margin-top: 0px;
  margin-bottom: 0px;

}
#navigation-icon {
  padding: 10px 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}
.content {
  position: relative;
  width: 100%;
  border-radius: 20px;
  transition: 1s transform cubic-bezier(0, 0.12, 0.14, 1);
}
.open {
  transform: translateX(250px);
}
</style>
