<script>
export default {
  created() {
    if (localStorage.getItem("darkMode") === "true") {
      this.$vuetify.theme.dark = true;
    }
  },
  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darkMode", this.$vuetify.theme.dark);
    },
  },
};
</script>
<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn v-on="on" icon @click="switchTheme">
        <v-icon>
          {{
            $vuetify.theme.dark
              ? "mdi-white-balance-sunny"
              : "mdi-weather-night"
          }}
        </v-icon>
      </v-btn>
    </template>
    <span>Change theme</span>
  </v-tooltip>
</template>
