<template>
  <v-footer dark padless>
    <v-card flat tile class="black white--text py-8 px-5" width="100%">
      <v-row>
        <v-col cols="12" sm="4">
          <v-card-text class="white--text pt-0">
            <h3>CountryGuess</h3>
          </v-card-text>
          <v-card-text class="grey--text pt-0">
            Challenge your knowledge of world geography with our Country
            Guessing Game! Test your skills by guessing the names of countries
            from around the globe. You'll have a limited number of attempts to
            correctly identify the country based on hints provided. Sharpen your
            geographical knowledge, have fun, and see how many countries you can
            guess correctly. Play now and explore the world one country at a
            time!
          </v-card-text>
          <v-toolbar flat color="transparent">
            <h5>All rights reserved to © 2023</h5>
            <a
              href="https://github.com/huseyintazee"
              class="text-h6 ml-4"
              style="color: inherit"
              >Huseyin Taze</a
            >
          </v-toolbar>
          <v-card-text class="grey--text"></v-card-text>
        </v-col>
        <v-col cols="12" sm="2"> </v-col>
        <v-col cols="12" sm="2"> </v-col>
        <v-col cols="12" sm="4">
          <v-card-text class="pt-0">
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-1 white--text"
              icon
            >
              <a :href="icon.link" target="_blank" style="color: inherit"
                ><v-icon size="24px">
                  {{ icon.icon }}
                </v-icon>
              </a>
            </v-btn>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { icon: "mdi-github", link: "https://github.com/huseyintazee" },
      {
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/in/huseyin-taze/",
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/tazehuseyinn/",
      },
      { icon: "mdi-twitter", link: "https://twitter.com/freshyyy78" },
    ],
  }),
};
</script>

<style></style>
