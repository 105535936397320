<template>
  <v-container v-if="!gameStarted">
    <v-row class="text-center mt-3">
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card>
          <v-img
            :src="require('../assets/gamebanner.png')"
            class="white--text align-center"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="400px"
            contain
          >
            <h1 class="text-center font-size">
              <span style="font-size: 24px">Guess the Country</span>
            </h1>
            <div class="text-center mt-6">
              <v-btn color="success" @click="countryGame('easy')">Easy</v-btn>
              <v-btn class="ml-2" color="warning" @click="countryGame('medium')"
                >Medium</v-btn
              >
              <v-btn class="ml-2" color="error" @click="countryGame('hard')"
                >Hard</v-btn
              >
            </div>
          </v-img>
        </v-card>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card>
          <v-img
            :src="require('../assets/flagbanner.png')"
            class="white--text align-center"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="400px"
            contain
          >
            <h1 class="text-center font-size">
              <span style="font-size: 24px">Guess the Flag</span>
            </h1>
            <div class="mt-6">
              <v-btn color="success" @click="flagGame('easy')">Easy</v-btn>
              <v-btn class="ml-2" color="warning" @click="flagGame('medium')"
                >Medium</v-btn
              >
              <v-btn class="ml-2" color="error" @click="flagGame('hard')"
                >Hard</v-btn
              >
            </div>
          </v-img>
        </v-card>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card>
          <v-img
            :src="require('../assets/europe.png')"
            class="white--text align-center"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="400px"
            contain
          >
            <h1 class="text-center font-size">
              <span style="font-size: 24px">MAPS</span>
            </h1>
            <div class="text-center">
              <v-btn class="white--text" @click="comingSoon" outlined
                >COMING SOON</v-btn
              >
            </div>
          </v-img>
        </v-card>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12">
        <v-card>
          <v-img
            :src="require('../assets/istanbul.png')"
            class="white--text align-center"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="400px"
            contain
          >
            <h1 class="text-center font-size">
              <span style="font-size: 24px">CITIES</span>
            </h1>
            <div class="text-center">
              <v-btn class="white--text" @click="comingSoon" outlined
                >COMING SOON</v-btn
              >
            </div>
          </v-img>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "StartGame",
  data: () => ({
    gameStarted: false,
    interval: 3000,
    difficulty: "",
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    },
  }),
  methods: {
    countryGame(difficulty) {
      this.$store.commit("gameStarted");
      this.$router.push({ name: "game" });
      this.$store.state.difficulty = difficulty;
    },
    flagGame(difficulty) {
      this.$store.commit("gameStarted");
      this.$router.push({ name: "flag" });
      this.$store.state.difficulty = difficulty;
    },
    comingSoon() {
      Swal.fire({
        title: "This mode is not ready yet. It will be available soon.",
        icon: "#4CAF50",
        color: "#4CAF50",
        confirmButtonText: "Ok",
        confirmButtonColor: "#4CAF50",
      });
    },
  },
};
</script>
<style scoped>
.coming-soon-card {
  height: 200px; /* Set the desired height of the card */
  width: 300px; /* Set the desired width of the card */
  display: flex;
  align-items: center;
  justify-content: center;
}

.coming-soon-text {
  font-size: 24px;
  font-weight: bold;
  color: white;
}
</style>
