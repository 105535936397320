<template>
  <div>
    <start-game />
  </div>
</template>

<script>
import StartGame from "../components/StartGame";

export default {
  name: "HomeView",

  components: {
    StartGame,
  },
};
</script>
